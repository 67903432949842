import {defineStore} from 'pinia';
import {ref} from '#imports';
import * as CABINET_CONSTANTS from '@/services/cabinet';
import type {ICompany} from '@/types/pages/cabinet/company';
import type {AsideMenuItem, User} from '@/types/store/cabinet';

export const useCabinetStore = defineStore('cabinet', () => {
  const asideMenu = ref<AsideMenuItem[]>(CABINET_CONSTANTS.asideMenu);
  const users = ref<User[]>(CABINET_CONSTANTS.users);
  const companies = ref<ICompany[]>([...CABINET_CONSTANTS.companies]);

  // TODO: Use api
  const getCompanyByfullName = (fullName: string) =>
    companies.value.find((company) => company.fullName === fullName);

  const setCompany = (i: number, company: ICompany) => {
    companies.value[i] = company;
  };

  const removeCompanyById = (i: number) => {
    if (companies.value.length !== 1) {
      companies.value.splice(i, 1);
    }
  };

  const addCompany = (company: ICompany) => {
    companies.value.push(company);
  };

  return {
    asideMenu,
    users,

    companies,
    getCompanyByfullName,
    setCompany,
    removeCompanyById,
    addCompany,
  };
});
