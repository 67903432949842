<template>
  <div class="aside-collapsible__col">
    <h2 class="aside-collapsible__title">
      {{ itemCopy.name }}
      <rotating-arrow
        class="aside-collapsible__arrow"
        :is-open="itemCopy.isOpen"
        @click="itemCopy.isOpen = !itemCopy.isOpen"
      ></rotating-arrow>
    </h2>
    <transition-group
      name="expand"
      @enter="useEnter($event)"
      @after-enter="useAfterEnter($event)"
      @leave="useLeave($event)"
    >
      <ul v-if="itemCopy.isOpen" class="aside-collapsible__items">
        <li
          v-for="child in itemCopy.childs"
          :key="child.id"
          class="aside-collapsible__item"
          :class="{disabled: !child.url}"
        >
          <h3 class="text-inherit font-inherit">
            <nuxt-link :to="child.url" class="hovered">{{ child.name }}</nuxt-link>
          </h3>
        </li>
      </ul>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import {type PropType, computed} from '#imports';
import RotatingArrow from '@/components/Base/rotatingArrow.vue';
import {useAfterEnter, useEnter, useLeave} from '@/composables/useAnimations';
import type {AsideMenuItem} from '@/types/store/cabinet';

const props = defineProps({
  item: {type: Object as PropType<AsideMenuItem>, required: true},
});

const emit = defineEmits<{
  (e: 'update:item', value: AsideMenuItem): void;
}>();

// TODO v-model not used
const itemCopy = computed({
  get() {
    return props.item;
  },
  set(val) {
    emit('update:item', val);
  },
});
</script>
